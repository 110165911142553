import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import store from "@/store";

export default {
  state: {
    calendars: null,
    selectedCalendars: null,
    selectedCalendarFields: null,
    calendar: null,
    activeCalendarType: null
  },
  mutations: {
    setCalendars(state, payload) {
      state.calendars = payload;
    },
    setSelectedCalendar(state, payload) {
      state.selectedCalendars = payload;
    },
    setSelectedCalendarFields(state, payload) {
      state.selectedCalendarFields = payload;
    },
    setCalendar(state, payload) {
      state.calendar = payload;
    },
    setActiveCalendarType(state, type) {
      state.activeCalendarType = type;
    }
  },
  actions: {
    getCalendars({ state }) {
      store.dispatch("pagination/paginate", {
        whatToCall: "connectorCalendars/getCalendars",
        whereToSave: "connectorCalendars/setCalendars",
        url: apiEndpoints.company.connectorCalendars,
        data: state.calendars,
        params: {}
      });
    },
    setCalendars({ commit }, payload) {
      commit("setCalendars", payload);
    },
    setSelectedCalendarFields({ commit }, payload) {
      commit("setSelectedCalendarFields", payload);
    },
    createCalendar({ commit, dispatch }, values) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.connectorCalendars}`;
      return httpServiceAuth
        .post(url, values)
        .then(() => {
          dispatch("getCalendars");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setSelectedCalendar({ commit }, payload) {
      commit("setSelectedCalendar", payload);
    },
    getCalendar({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.connectorCalendars}/${id}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setCalendar", response.data.data);
          return response.data.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    updateCalendar({ commit, dispatch }, { data, id }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.connectorCalendars}/${id}`;

      return httpServiceAuth
        .put(url, data)
        .then(response => {
          commit("setCalendar", response.data.data);
          dispatch("getCalendars");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteCalendar({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(`${apiEndpoints.company.connectorCalendars}/${id}`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    updateActiveCalendarType({ commit }, type) {
      commit("setActiveCalendarType", type);
    }
  }
};
