export const apiEndpoints = {
  company: {
    login: "company/v1/auth/login",
    loginSsoOutlook: "company/v1/auth/outlooksso",
    initial: "company/v1/auth/initial",
    tempCode: "company/v1/auth/temp-code",
    logout: "company/v1/auth/logout",
    refresh: "company/v1/auth/refresh",
    authDevice: "company/v1/auth/device",
    users: "company/v1/users",
    userBulkDelete: "company/v1/userbulkdelete",
    languages: "company/v1/languages",
    timezones: "company/v1/timezones",
    currencies: "company/v1/currencies",
    currentCompany: "company/v1/current",
    connectorTypes: "company/v1/connectortypes",
    linkedUsers: "company/v1/linkedusers",
    connectors: "company/v1/connectors",
    sso: "company/v1/sso/auth/login/redirect",
    ssoLogin: "company/v1/sso/auth/login",
    levels: "company/v1/levels",
    countries: "company/v1/countries",
    versions: "company/v1/versions",
    temptoken: "company/v1/temptokens",
    resources: "company/v1/resources",
    resourceTypes: "company/v1/resourcetypes",
    resourceAttributes: "company/v1/resourceattributes",
    resourceCapacityType: "company/v1/resourcecapacitytypes",
    resourceModel: "company/v1/resourcemodels",
    resourceFunctionType: "company/v1/resourceconnectortypes",
    resourceLinked: "company/v1/resourcelinked",
    resourceDistances: "company/v1/resourcedistances",
    resourceCalendars: "company/v1/resource-calendars",
    resourceEvents: "company/v1/resource-events",
    slots: "company/v1/plan/slots",
    passwordForgot: "company/v1/auth/forgot-password",
    passwordReset: "company/v1/auth/reset-password",
    occupancy: "company/v1/plan/occupancies",
    declarations: "company/v1/plan/declarations",
    declarationCost: "company/v1/plan/declaration/cost/calculate",
    maps: "company/v1/maps",
    resourceFunctionsTypes: "/company/v1/resourcefunctiontypes",
    applications: "company/v1/applications",
    sections: "company/v1/labelsections",
    megaContainers: "company/v1/megacontainers",
    containerAccessTypes: "company/v1/accesstypes",
    containers: "company/v1/containers",
    allowedContainers: "company/v1/receptiondeskallowedcontainers",
    roles: "company/v1/roles",
    preferenceCategories: "company/v1/preferencecategories",
    userPreferences: "company/v1/userpreferences",
    slotsAvailable: "company/v1/userpreferences/slots/available",
    companyPreferences: "company/v1/preferences",
    permissions: "company/v1/permissions",
    permissiontypes: "company/v1/permissiontypes",
    permissioncategories: "company/v1/permissioncategories",
    generalPermissions: "company/v1/generalpermissions",
    planResourceLimit: "/company/v1/plan/limits/resources",
    userAssignableContainers: "/company/v1/users/assignable-containers",
    spacePasses: "/company/v1/plan/spacepasses",
    receptionDesks: "/company/v1/receptiondesks",
    companyProfile: "company/v1/companyprofile",
    declarationActions: "/company/v1/plan/declarationactions/",
    planScan: "/company/v1/plan/scan",
    outlookSync: "/company/v1/azure/calendar/events",
    googleCalendarAuth: "/company/v1/calendar/google/auth",
    exportDeclarations: "/company/v1/report/declarations",
    declarationInitials: "/company/v1/report/declaration-initials",
    userProfileImage: "/company/v1/userprofileimage",
    companyProfileImage: "/company/v1/companyprofileimage",
    companyLogo: "/company/v1/logo",
    resourceAssignments: "/company/v1/resourceassignments",
    logsExport: "/company/v1/logexport",
    logsConfigs: "/company/v1/logconfigs",
    visitors: "/company/v1/visitors",
    visitorBadge: "/company/v1/visitorbadge",
    widgets: "/company/v1/widgets",
    widgetOneClick: "/company/v1/widget/oneclick",
    widgetOneClickPositions: "/company/v1/widget/widget-positions",
    widgetUsers: "/company/v1/widget/users",
    widgetContacts: "/company/v1/widget/persons",
    widgetPlans: "/company/v1/widget/plans",
    widgetMaps: "/company/v1/widget/maps",
    widgetPeriods: "/company/v1/widget/periods",
    widgetPlanPositions: "/company/v1/widget/plan-positions",
    widgetMapPositions: "/company/v1/widget/map-positions",
    widgetInfo: "/company/v1/widget/info",
    widgetInfoPositions: "/company/v1/widget/info-positions",
    widgetFindPerson: "/company/v1/widget/person/locate",
    qrCodeTemplates: "/company/v1/qrcodetemplates",
    qrCodes: "/company/v1/qrcodes",
    qrCode: "/company/v1/qrcode",
    qrCodeScan: "/company/v1/qrcode-scan",
    qrCodeExports: "/company/v1/qrcode/exports",
    countSettings: "/company/v1/count/settings",
    countFilters: "/company/v1/count/filters",
    countExport: "/company/v1/count/export",
    countCalculate: "/company/v1/count/calculate",
    countTechnicalList: "/company/v1/count/technical-list",
    countPresets: "/company/v1/count/presets",
    occupancySettings: "/company/v1/occupancy/settings",
    occupancyFilters: "/company/v1/occupancy/filters",
    occupancyExport: "/company/v1/occupancy/export",
    occupancyCalculate: "/company/v1/occupancy/calculate",
    occupancyTechnicalList: "/company/v1/occupancy/technical-list",
    occupancyPresets: "/company/v1/occupancy/presets",
    occupancyLevels: "/company/v1/occupancy/level",
    cleanResources: "/company/v1/resource-clean",
    companyDocuments: "/company/v1/companydocuments",
    userAcceptance: "/company/v1/useracceptance",
    outlookResource: "/company/v1/outlook-resources",
    outlookResources: "/company/v1/calendar/microsoft/outlook/addins",
    visits: "/company/v1/visits",
    myvisits: "/company/v1/myvisits",
    cockpitWidgets: "/company/v1/cockpitwidgets",
    cockpitWidgetActivate: "/company/v1/cockpitwidgets/activate",
    cockpitWidgetTypes: "/company/v1/cockpitwidgettypes",
    cockpitWidgetsOrder: "/company/v1/cockpitwidgets/order",
    cockpitWidgetItems: "/company/v1/cockpitwidgetitems",
    searchFilters: "/company/v1/search/filters",
    searchPresets: "/company/v1/search/presets",
    bookings: "/company/v1/bookings",
    helpOnline: "/company/v1/help/pages",
    declarationApproveStatuses: "/company/v1/declarationapprovestatuses",
    delegatePermissions: "/company/v1/delegatepermissions",
    delegateUsers: "/company/v1/delegateusers",
    display: "/company/v1/display",
    displayMapDateTime: "/company/v1/display/map-datetime",
    displayHeartbeat: "/company/v1/display/heartbeat",
    displayClearTokens: "/company/v1/display/clear-tokens",
    realtimeUpdateTokens: "/company/v1/map/update-tokens",
    realtimeDeleteTokens: "/company/v1/map/delete-tokens",
    devices: "/company/v1/display/devices",
    deviceFilters: "/company/v1/display/filters",
    deviceUsers: "/company/v1/device-users",
    receptionDesk: "/company/v1/reception-desk",
    receptionDeskScan: "/company/v1/reception-desk/scan",
    receptionDeskHeartbeat: "/company/v1/reception-desk/heartbeat",
    receptionDeskCamera: "/company/v1/reception-desk/camera",
    realtimeHeartbeat: "/company/v1/realtime-desk/heartbeat",
    deviceTemplates: "/company/v1/device-templates",
    intranet: "/company/v1/connector-intranets",
    connectorCalendars: "/company/v1/connector-calendars",
    connectorCalendarFields: "/company/v1/connector-calendar-fields",
    serviceItems: "/company/v1/serviceitems",
    serviceTypes: "company/v1/servicetypes",
    serviceOrders: "/company/v1/serviceorders",
    serviceStatuses: "/company/v1/servicestatuses",
    serviceResources: "/company/v1/serviceresources",
    resourceDetails: "/company/v1/serviceorders/resource",
    resourceServiceTypes: "/company/v1/servicetypes/resource",
    serviceOrderItems: "/company/v1/serviceorderitems",
    exportServices: "/company/v1/report/serviceorders",
    serviceHistory: "/company/v1/servicehistory",
    anonymousService: "/company/v1/anonymousservice",
    displayAnonymousBooking: "/company/v1/display/anonymous-booking",
    displayAnonymousCheckin: "/company/v1/display/anonymous-checkin",
    displayAnonymousCheckout: "/company/v1/display/anonymous-checkout",
    visitorDownloadExample: "/company/v1/visitorexportexample",
    visitorsImport: "/company/v1/visitorsimport",
    visitorsReport: "/company/v1/visitorsreport",
    companyNotifications: "/company/v1/companynotifications",
    userNotifications: "/company/v1/pushnotificationstoken",
    showGrid: "/company/v1/bookings/showgrid",
    bookingGrid: "/company/v1/bookinggrid",
    bookingResize: "/company/v1/bookings/resize",
    levelsContainer: "/company/v1/levels/container",
    resourceInfo: "/company/v1/resourceinfo",
  },
  master: {
    login: "master/v1/auth/login",
    initial: "master/v1/auth/initial",
    loginWithCode: "company/v1/auth/temp-code",
    logout: "master/v1/auth/logout",
    refresh: "master/v1/auth/refresh",
    auth2fa: "master/v1/auth/2fa",
    users: "master/v1/users",
    userBulkDelete: "company/v1/userbulkdelete",
    applications: "master/v1/applications",
    companies: "master/v1/companies",
    companyTypes: "master/v1/companytypes",
    countries: "master/v1/countries",
    languages: "master/v1/languages",
    timezones: "master/v1/timezones",
    currencies: "master/v1/currencies",
    labels: "master/v1/labels",
    companyLabels: "master/v1/company-labels",
    sections: "master/v1/labelsections",
    pages: "master/v1/labelpages",
    labelpages: "master/v1/labelpages",
    connectorTypes: "/master/v1/connectortypes",
    linkedUsers: "master/v1/linkedusers",
    connectors: "master/v1/connectors",
    sso: "master/v1/sso/auth/login/redirect",
    ssoLogin: "master/v1/sso/auth/login",
    versions: "master/v1/versions",
    temptoken: "master/v1/temptokens",
    passwordForgot: "master/v1/auth/forgot-password",
    passwordReset: "master/v1/auth/reset-password",
    dataRegions: "master/v1/dataregions",
    endesaCount: "master/v1/company/endesa/count",
    syncTranslations: "master/v1/sync-translations",
  },
  poe: {
    addTranslation: "https://api.poeditor.com/v2/terms/add",
  },
  ws: {
    auth: "/broadcasting/auth",
  },
};

export const qrCodeTypes = [
  {
    id: 1,
    name: "resource",
  },
  {
    id: 2,
    name: "level",
  },
  {
    id: 4,
    name: "plan",
  },
  {
    id: 5,
    name: "visit",
  },
  {
    id: 6,
    name: "space-pass",
  },
  // {
  //   id: 7,
  //   name: "user"
  // }
  // {
  //   id: 3,
  //   name: "booking"
  // },
  // {
  //   id: 8,
  //   name: "service"
  // },
  // {
  //   id: 9,
  //   name: "external-resource"
  // }
];

export const declarationStatuses = {
  CREATED: "CREATED",
  PRE_CHECK_IN: "PRE_CHECK_IN", // yellow
  STARTED: "STARTED", // green
  CHECKED_IN: "CHECKED_IN", // green
  CHECKED_OUT: "CHECKED_OUT",
  AUTO_RELEASED: "AUTO_RELEASED",
  EXTENDED: "EXTENDED", // green
  EXPIRING_WARNING: "EXPIRING_WARNING", // red
  ENDED: "ENDED",
};

export const fullScreenPaths = [
  "r_qr-code-image",
  "r_qr-code-read",
  "r_endesa-count",
  "r_display",
  "r_self-registration-reception-desk",
  "r_service",
];

/**
 * Websocket events
 */
export const wsEvents = {
  declaration: {
    changed: "declaration.changed",
  },
  receptionDesk: {
    visitor: {
      changed: "receptionDesk.visitor.changed",
    },
  },
  endesa: {
    changed: "endesa.changed",
  },
  connectors: {
    changed: "connectors.changed",
  },
  resourceFunctions: {
    changed: "resource-functions.changed",
  },
  companyProfile: {
    changed: "company-profile.changed",
  },
  resourceChange: {
    changed: "resource-functions.changed",
  },
  declarationChange: {
    changed: "declaration.changed",
  },
  declarationDecline: {
    changed: "declaration.declined",
  },
  levelChange: {
    changed: "level.changed",
  },
  receptionDeskChange: {
    changed: "receptionDesk.changed",
  },
  serviceOrders: {
    changed: "service-orders.changed",
  },
  serviceItem: {
    changed: "service-item.changed",
  },
  grid: {
    changed: "grid.changed",
  },
};

// One click widgets id map
export const oneClickWidgets = {
  plan: 1,
  spacePass: 2,
  findPerson: 3,
  info: 4,
  map: 5,
};
