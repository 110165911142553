import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import store from "@/store";

export default {
  state: {
    calendarFields: null,
    selectedCalendarFields: null
  },
  mutations: {
    setCalendarFields(state, payload) {
      state.calendarFields = payload;
    },
    setSelectedCalendarFields(state, payload) {
      state.selectedCalendarFields = payload;
    },
  },
  actions: {
    setCalendarFields({ commit }, payload) {
      commit("setCalendarFields", payload);
    },
    setSelectedCalendarFields({ commit }, payload) {
      commit("setSelectedCalendarFields", payload);
    },
    createCalendarField({ commit, dispatch }, values) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.connectorCalendarFields}`;
      return httpServiceAuth
        .post(url, values)
        .then(() => {
          store.dispatch("connectorCalendars/getCalendars");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getCalendarFields({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.connectorCalendarFields}/${id}`;
      return httpServiceAuth
        .get(url)
        .then(response => {
          commit("setCalendarFields", response.data.data);
          return response.data.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    updateCalendarFields({ commit, dispatch }, { data, id }) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.connectorCalendarFields}/${id}`;

      return httpServiceAuth
        .put(url, data)
        .then(response => {
          commit("setCalendarFields", response.data.data);
          store.dispatch("connectorCalendars/getCalendars");
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    deleteCalendarField({ commit }, id) {
      commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .delete(`${apiEndpoints.company.connectorCalendarFields}/${id}`)
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    }
  }
};
